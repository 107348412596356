@import "../../mixin.scss";
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
.text-right { 
  text-align: right;
}
.disabled-control { 
 // background-color: #ffffff63 !important;
 opacity: .6 !important;
}
.formInputQuantity .inputQuantity {
  background-color: #fff;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  color: #7a7a7a;
  display: inline-block;
  font-size: 16px;
  min-height: 36px;
  max-height: 50px;
  line-height: normal;
  outline: 0;
  text-align: center;
  width: 100%;
  width: 40px;
}
#welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: white;
  padding: 2rem 0;

  #background-lights {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  h1 {
    font-size: 3em;
    font-weight: 400;
    margin: 0.1em 0;
    @include small {
      font-size: 2.5em;
    }
  }

  h3 {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5em;
    font-weight: 700;
    margin: 1em 0 2em 0;
    @include small {
      font-size: 1em;
      width: 80%;
    }
  }

  .links {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
    @include medium {
      flex-direction: column;
      width: 100%;
    }

    a {
      display: flex;
      height: 120px;
      width: 300px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      font-size: 1.5em;
      border-radius: 30px;
      margin: 0 1em;
      @include medium {
        margin-bottom: 1.5em;
      }
    }

    .monthly {
      background: rgb(217, 109, 162);
      background: linear-gradient(
        170deg,
        rgba(217, 109, 162, 1) 0%,
        rgba(101, 60, 162, 1) 100%
      );
    }

    .daily {
      background: rgb(78, 184, 217);
      background: linear-gradient(
        130deg,
        rgba(78, 184, 217, 1) 0%,
        rgba(51, 113, 202, 1) 42%
      );
    }
  }
  .plan-title {
    font-size: 2em;
    margin: 4em 0 0 0;
  }

  .plans {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin-top: 2em;
    transition: 1s;

    @include medium {
      flex-direction: column;
      align-items: center;
      a {
        margin-bottom: 2em;
      }
    }

    img {
      max-width: 300px;
      max-height: 550px;
      opacity: 0.5;
      transition: 0.5s;
      margin: 0 1em;

      &:hover {
        opacity: 0.8;
        transform: scale(1.025);
      }
    }
  }





}
.formIMEI {
  border: 2px solid #efdede;
  border-radius: 8px;
  display: inline-block;
  margin: 20px 0;
  padding: 40px;
  width: 100%;
}
.textFormIMEI {
  color: #e8eaed;
}
