@import "../../mixin.scss";

.payment-iframe {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  flex-direction: column;
  position: fixed;

  .background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    backdrop-filter: blur(5px);
    z-index: -1;
  }

  svg {
    align-self: flex-end;
    margin: 0.5em;
    @include small {
      margin: 0.2em;
    }
  }
  @media only screen and (min-width:1001px) and (max-width: 4000px) { 
    iframe {
      margin: auto;
      height: 790px;
      width: 400px;
      @include small {
        width: 300px;
        margin-bottom: 0.5em;
      }
    }
  }

  @media only screen and (min-width:500px) and (max-width: 1000px) { 
    iframe {
      margin: auto;
      height: 620px;
      width: 400px;
      @include small {
        width: 300px;
        margin-bottom: 0.5em;
      }
    }
  }

  @media only screen and (min-width:320px) and (max-width: 499px) { 
    iframe {
      margin: auto;
      height: 600px;
      width: 400px;
      @include small {
        width: 300px;
        margin-bottom: 0.5em;
      }
    }

    
  }
 
  @media only screen and (min-width:100px) and (max-width: 319px) { 
    iframe {
      margin: auto;
      height: 500px;
      width: 400px;
      @include small {
        width: 300px;
        margin-bottom: 0.5em;
      }
    }

    
  }

}
