@import "../../mixin.scss";

.header {
  display: flex;
  flex-direction: column;

  .top-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;

    .logo {
      padding: 1rem 4rem;
      @include small {
        padding: 1rem;
      }
      img {
        max-width: 160px;
        @include small {
          max-width: 130px;
        }
      }
    }

    .menu-icon {
      padding: 2rem 4rem;
      z-index: 1;
      @include small {
        padding: 1rem;
      }

      img {
        width: 40px;
        cursor: pointer;
      }

      svg {
        width: 40px;
        height: 40px;
        cursor: pointer;
        color: white;
      }
    }
  }

  .menu {
    display: flex;
    background-color: #24135f;
    color: #fff;
    width: 100%;
    min-height: 90vh;
    animation: fade-right 0.5s;
    @include small {
      flex-direction: column-reverse;
      align-items: center;
    }

    .video {
      width: 33.3%;
      @include medium {
        display: none;
      }

      .menu-video {
        width: 100%;
        height: 100%;
        max-width: 490px;
        object-fit: cover;
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-top: 5em;
      width: 33.3%;
      @include medium {
        width: 50%;
      }

      @include small {
        width: 100%;
        align-items: center;
        animation: fade-right-text 1.5s;
      }

      a {
        text-decoration: none;
        font-weight: 500;
        color: white;
        font-size: 20px;
        @include small {
          margin-bottom: 1em;
        }
      }

      h1 {
        font-size: 4.5rem;
        @include small {
          font-size: 3em;
        }
      }
    }

    .social-media-icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 5em;
      width: 33.3%;
      @include medium {
        width: 50%;
        padding: 2em 0;
      }

      @include small {
        width: 100%;
        align-items: center;
      }

      h2 {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 0.5em;
        animation: fade-top 1s;
        animation-delay: 0.2s;
        animation-fill-mode: both;

        @include small {
          font-size: 1.5rem;
        }
      }

      p,
      b {
        font-size: 1.5rem;
        margin: 0 1px;
        animation: fade-top 1s;
        animation-delay: 0.6s;
        animation-fill-mode: both;
      }

      b {
        color: #7b32ef;
        font-weight: 500;
      }

      .icons {
        display: flex;
        width: 100%;
        margin-left: 50px;

        svg {
          color: #9445b3;
          width: 80px;
          height: 30px;
        }
      }
    }
  }
}

@keyframes fade-right {
  from {
    opacity: 0;
    transform: translate3d(10em, 0, 6em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-right-text {
  from {
    opacity: 0;
    transform: translate3d(1em, 0, 5em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-top {
  from {
    opacity: 0;
    transform: translate3d(0, -1em, 5em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.form-label{
  color: #fff;
}

.requiredLabel{
  color:red;
}
.detailCheckout {
  padding: 5px 40px;
}
