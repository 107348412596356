@mixin small {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin medium {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin large {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}
