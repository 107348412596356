body {
  margin: 0;
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #150b4b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Responsive mixins
// Material Design breakpoints

@mixin small {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}

// Fonts

@font-face {
  font-family: "Proxima Nova";
  src: url("assets/fonts/Proxima\ Nova\ Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("assets/fonts/Proxima\ Nova\ Thin.otf");
  font-weight: lighter;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("assets/fonts/ProximaNova-Regular.otf");
  font-weight: normal;
}
