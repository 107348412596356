@import "../../mixin.scss";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8246af;
  color: white;

  .logo {
    padding: 1rem 0;
    img {
      max-width: 250px;
    }
  }

  .links {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    @include small {
      flex-direction: column;
      align-items: center;
    }

    a {
      font-weight: 500;
      text-decoration: none;
      color: white;
      font-size: 1.2rem;
      padding: 0 1em;
      @include small {
        margin-bottom: 1em;
      }
    }
  }

  .social-media-icons {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      color: white;
      padding: 0 3em;
      @include small {
        padding: 0 1em;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }

    h4 {
      font-size: 20px;
    }
  }
}
